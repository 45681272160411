exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-land-tsx": () => import("./../../../src/pages/land.tsx" /* webpackChunkName: "component---src-pages-land-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-project-spec-taman-kobat-residensi-2-tsx": () => import("./../../../src/pages/project/spec/taman-kobat-residensi-2.tsx" /* webpackChunkName: "component---src-pages-project-spec-taman-kobat-residensi-2-tsx" */),
  "component---src-pages-project-taman-kobat-residensi-2-tsx": () => import("./../../../src/pages/project/taman-kobat-residensi-2.tsx" /* webpackChunkName: "component---src-pages-project-taman-kobat-residensi-2-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resources-faqs-tsx": () => import("./../../../src/pages/resources/faqs.tsx" /* webpackChunkName: "component---src-pages-resources-faqs-tsx" */),
  "component---src-pages-resources-glossary-tsx": () => import("./../../../src/pages/resources/glossary.tsx" /* webpackChunkName: "component---src-pages-resources-glossary-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

